<template>
  <v-app>
    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",

  components: {},

  data: () => ({
    //
  }),
};
</script>
<style lang="scss">
// Import Main styles for this application
@import "assets/scss/style";
</style>
<style src="@/assets/styles/form.css" />
<style src="@/assets/styles/global.css" />
<style src="@/assets/styles/button.css" />
<style src="@/assets/styles/form.css" />
<style src="@/assets/styles/table.css" />
<style>
th span {
  font-size: 1.05rem !important;
  color: rgb(15, 15, 15) !important;
}

td,
td div span {
  font-size: 1.02rem !important;
}

td span {
  font-size: 0.89rem;
}

.v-data-footer {
  font-size: 0.9rem !important;
}
</style>
