import store from "./store";
import axios from "axios";

store.subscribe((mutation) => {
    switch (mutation.type) {
        case "auth/SET_HEADER":
            if (mutation.payload) {
                axios.defaults.headers = JSON.parse(mutation.payload);
                localStorage["headers"] = mutation.payload;
            }
            break;
    }
});