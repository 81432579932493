import Vue from "vue";
import Vuex from "vuex";
import auth from "./auth.module";

Vue.use(Vuex);

const state = {
    sidebarShow: "responsive",
    sidebarMinimize: false,
};

const mutations = {
    toggleSidebarDesktop(state) {
        const sidebarOpened = [true, "responsive"].includes(state.sidebarShow);
        state.sidebarShow = sidebarOpened ? false : "responsive";
    },
    toggleSidebarMobile(state) {
        const sidebarClosed = [false, "responsive"].includes(state.sidebarShow);
        state.sidebarShow = sidebarClosed ? true : "responsive";
    },
    set(state, [variable, value]) {
        state[variable] = value;
    },
};

const modules = {
    auth,
};
const actions = {};
export default new Vuex.Store({
    state,
    mutations,
    actions,
    modules,
});