/* eslint-disable no-unused-vars */
import "regenerator-runtime/runtime";
import "core-js/stable";
import Vue from "vue";
import App from "./App";
import router from "./router";
import CoreuiVue from "@coreui/vue";
import { iconsSet as icons } from "./assets/icons/icons.js";
import store from "./store/store";
import vuetify from "./plugins/vuetify";
import VueToast from "vue-toast-notification";
import "vue-toast-notification/dist/theme-sugar.css";
import axios from "axios";
import Calls from "./Calls";
var $ = require("jquery");

axios.defaults.baseURL = Calls.baseUrl();
require("./store/subscriber");

Vue.config.performance = true;
Vue.use(CoreuiVue);
Vue.use(VueToast);
Vue.prototype.$log = console.log.bind(console);

store.dispatch("auth/attempt", localStorage["headers"]);

new Vue({
    el: "#app",
    router,
    store,
    icons,
    template: "<App/>",
    vuetify,

    components: {
        App,
    },
});