import axios from "axios";

const baseUrl = "https://api.easyflip.ng/api/v1";

export default {
    baseUrl() {
        return baseUrl;
    },

    getUser() {
        return axios
            .get(`${baseUrl}/user`, { headers: JSON.parse(localStorage["headers"]) })
            .then((res) => {
                return res.data.data;
            });
    },
    getWallet() {
        return axios
            .get(`${baseUrl}/user`, { headers: JSON.parse(localStorage["headers"]) })
            .then((res) => {
                return res.data.data.wallet;
            });
    },
    getGiftCardCart() {
        return axios
            .get(`${baseUrl}/giftcard/categories`, {
                headers: JSON.parse(localStorage["headers"]),
            })
            .then((res) => {
                return res.data;
            })
            .catch((err) => {
                return err.response.data;
            });
    },
    formartNumber(value) {
        var val = parseFloat(value).toFixed(2);
        var val2 = val.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
        if (val2 == "NaN") {
            return "loading...";
        } else {
            return val2;
        }
    },
    formartDate(value) {
        var day = new Date(value);
        return day.toString().slice(0, 15);
    },
};