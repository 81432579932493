import axios from "axios";
import Calls from "@/Calls";
export default {
    namespaced: true,
    state: {
        headers: null,
        user: null,
    },
    mutations: {
        SET_HEADER(state, headers) {
            state.headers = headers;
        },
        SET_USER(state, user) {
            state.user = user;
        },
    },
    getters: {
        authenticated(state) {
            return state.user && state.headers;
        },
        user(state) {
            return state.user;
        },
        test() {
            return "testing";
        },
        headers(state) {
            return JSON.parse(state.headers);
        },
    },
    actions: {
        async signIn({ commit }, body) {
            // eslint-disable-next-line no-async-promise-executor
            return new Promise(async(resolve, reject) => {
                try {
                    const response = await axios.post(
                        `${Calls.baseUrl()}/auth/login`,
                        body
                    );
                    commit("SET_HEADER", JSON.stringify(response.headers));
                    commit("SET_USER", response.data.data);
                    resolve(response.data);
                } catch (error) {
                    reject(error.response.data);
                }
            });
        },

        async attempt({ commit }, headers) {
            if (headers) {
                commit("SET_HEADER", headers);
            }
        },
    },
};