import Vue from "vue";
import Router from "vue-router";
import store from "../store/store";
const beforeEnterFunction = (to, from, next) => {
    if (store.state.auth.headers) {
        return next();
    }

    next("/login");
};
// Containers
const TheContainer = () =>
    import ("@/containers/TheContainer");

// Views
const Dashboard = () =>
    import ("@/views/Dashboard");

// Users
const Users = () =>
    import ("@/views/users/Users");
const User = () =>
    import ("@/views/users/User");

Vue.use(Router);

export default new Router({
    mode: "history",
    linkActiveClass: "active",
    scrollBehavior: () => ({ y: 0 }),
    routes: configRoutes(),
});

function configRoutes() {
    return [{
            path: "*",
            redirect: "/dashboard",
        },

        {
            path: "/",
            redirect: "/login",
        },

        {
            path: "/login",
            component: () =>
                import ("@/views/auth/Login"),
        },

        {
            path: "/",
            redirect: "/dashboard",
            name: "Home",
            component: TheContainer,
            beforeEnter: (to, from, next) => {
                beforeEnterFunction(to, from, next);
            },
            children: [{
                    path: "dashboard",
                    name: "Dashboard",
                    component: Dashboard,
                },
                {
                    path: "all_giftcard_categories",
                    name: "All GiftCard",
                    component: () =>
                        import ("@/views/giftcard/AllCategories"),
                },
                {
                    path: "all_giftcard_categories/:id",
                    name: "Single GiftCard Category",
                    component: () =>
                        import ("@/views/giftcard/SingleCategory"),
                },
                {
                    path: "uploaded_giftcard",
                    name: "Uploaded GiftCard",
                    component: () =>
                        import ("@/views/giftcard/UploadedGiftCards"),
                },
                {
                    path: "users_giftcard",
                    name: "Users GiftCard",
                    component: () =>
                        import ("@/views/giftcard/UsersUploaded"),
                },
                {
                    path: "create_giftcard",
                    name: "Create GiftCard",
                    component: () =>
                        import ("@/views/giftcard/CreateCategory"),
                },
                {
                    path: "/wallet_stat",
                    name: "Wallet Statistics",
                    component: () =>
                        import ("@/views/wallet/WalletStats.vue"),
                },
                {
                    path: "/transaction_log",
                    name: "Transaction Log",
                    component: () =>
                        import ("@/views/wallet/TransactionLog.vue"),
                },
                {
                    path: "all_users",
                    meta: {
                        label: "Users",
                    },
                    component: {
                        render(c) {
                            return c("router-view");
                        },
                    },
                    children: [{
                            path: "",
                            name: "Users",
                            component: Users,
                        },

                        {
                            path: "update_password",
                            name: "Update Pasword",
                            component: () =>
                                import ("@/views/users/UpdatePassword.vue"),
                        },
                        {
                            path: ":id",
                            meta: {
                                label: "User Details",
                            },
                            name: "User",
                            component: User,
                        },
                    ],
                },
                {
                    path: "notifications",
                    meta: {
                        label: "Notifications",
                    },
                    component: {
                        render(c) {
                            return c("router-view");
                        },
                    },
                    children: [{
                            path: "",
                            name: "All Notifications",
                            component: () =>
                                import ("@/views/notifications/All.vue"),
                        },
                        {
                            path: "/notifications/:id",
                            name: "Notification Details",
                            component: () =>
                                import ("@/views/notifications/Single.vue"),
                        },
                    ],
                },

                {
                    path: "withdrawal",
                    meta: {
                        label: "Withdrawal",
                    },
                    component: {
                        render(c) {
                            return c("router-view");
                        },
                    },
                    children: [{
                        path: "",
                        name: "Withdrawal Requests",
                        component: () =>
                            import ("@/views/withdrawal/All.vue"),
                    }, ],
                },
            ],
        },
    ];
}